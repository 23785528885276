import React from 'react';
import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { AllowedTo } from "../lib/permission";
import { Permission } from "../lib/permission/config";

const HeaderDropdownHelp = () => (
  <CDropdown
    className="c-header-nav-items mx-2"
    direction="down"
  >
    <CDropdownToggle className="c-header-nav-link" caret={true}>
      <div style={{ fontSize: "14px"}}>ヘルプ</div>
    </CDropdownToggle>
    <CDropdownMenu className="pt-0" placement="bottom-end">
      <CDropdownItem
        header
        tag="div"
        color="light"
        className="text-center"
      >
        <strong>ヘルプ</strong>
      </CDropdownItem>
      <CDropdownItem href="https://manual.shadan-kun.com/page/v2" target="_blank">
        <CIcon name="cil-notes" />&nbsp;マニュアル
      </CDropdownItem>
      <CDropdownItem href="https://docs.shadan-kun.com/manual/server_type_specifications_v2.pdf" target="_blank">
        <CIcon name="cil-file" />&nbsp;サービス仕様書
      </CDropdownItem>
      <CDropdownItem href="https://help.shadan-kun.com" target="_blank">
        <div style={{ fontSize: "18px", fontWight: "bold"}}>？&nbsp;</div>&nbsp;FAQ
      </CDropdownItem>
      <AllowedTo perform={Permission.CREATE_INQUIRY}>
        <CDropdownItem to="/inquiry">
          <CIcon name="cil-comment-square" />&nbsp;お問い合わせ
        </CDropdownItem>
      </AllowedTo>
    </CDropdownMenu>
  </CDropdown>
);

export default HeaderDropdownHelp;

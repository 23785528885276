import React from 'react';

const TwoFactorAuthNew = React.lazy(() => import("../components/two-factor-auth/TwoFactorAuthNew"));
const TwoFactorAuthDelete = React.lazy(() => import("../components/two-factor-auth/TwoFactorAuthDelete"));
const TwoFactorAuthCode = React.lazy(() => import("../components/two-factor-auth/TwoFactorAuthCode"));
const Dashboard = React.lazy(() => import("../components/dashboard/Dashboard"));
const Analysis = React.lazy(() => import("../components/analysis/Analysis"));
const AttackLogList = React.lazy(() => import("../components/attack-logs/AttackLogList"));
const UserList = React.lazy(() => import("../components/users/UserList"));
const UserCreate = React.lazy(() => import("../components/users/UserCreate"));
const UserEdit = React.lazy(() => import("../components/users/UserEdit"));
const UserGroupList = React.lazy(() => import("../components/user-groups/UserGroupList"));
const UserGroupCreate = React.lazy(() => import("../components/user-groups/UserGroupCreate"));
const UserGroupShow = React.lazy(() => import("../components/user-groups/UserGroupShow"));
const BillingDestinationList = React.lazy(() => import("../components/billing-destinations/BillingDestinationList"));
const BillingDestinationCreate = React.lazy(() => import("../components/billing-destinations/BillingDestinationCreate"));
const BillingDestinationEdit = React.lazy(() => import("../components/billing-destinations/BillingDestinationEdit"));

const CustomerList = React.lazy(() => import("../components/customers/CustomerList"));
const CustomerCreate = React.lazy(() => import('../components/customers/CustomerCreate'));
const CustomerEdit = React.lazy(() => import("../components/customers/CustomerEdit"));
const CustomerShow = React.lazy(() => import("../components/customers/CustomerShow"));
const DistributorList = React.lazy(() => import("../components/distributors/DistributorList"));
const DistributorCreate = React.lazy(() => import("../components/distributors/DistributorCreate"));
const DistributorEdit = React.lazy(() => import("../components/distributors/DistributorEdit"));
// const PricePlanList = React.lazy(() => import("../components/price-plans/PricePlanList"));
// const PricePlanCreate = React.lazy(() => import("../components/price-plans/PricePlanCreate"));
// const PricePlanEdit = React.lazy(() => import("../components/price-plans/PricePlanEdit"));
// const PricePlanDelete = React.lazy(() => import("../components/price-plans/PricePlanDelete"));
const LicenseList = React.lazy(() => import("../components/licenses/LicenseList"));
const LicenseCreate = React.lazy(() => import("../components/licenses/LicenseCreate"));
const LicenseEdit = React.lazy(() => import("../components/licenses/LicenseEdit"));
const SrvWafConfigShow = React.lazy(() => import("../components/srv-waf-configs/SrvWafConfigShow"));
const SrvWafConfigList = React.lazy(() => import("../components/srv-waf-configs/SrvWafConfigList"));
const SrvWafConfigCreate = React.lazy(() => import("../components/srv-waf-configs/SrvWafConfigCreate"));
const AccessControlList = React.lazy(() => import("../components/srv-waf-configs/AccessControlList"));
const SrvPlatformList = React.lazy(() => import("../components/srv-platforms/SrvPlatformList"));
const SrvPlatformCreate = React.lazy(() => import("../components/srv-platforms/SrvPlatformCreate"));
const SrvPlatformEdit = React.lazy(() => import("../components/srv-platforms/SrvPlatformEdit"));
const SrvpfServerList = React.lazy(() => import("../components/srvpf-servers/SrvpfServerList"));
const SrvpfServerCreate = React.lazy(() => import("../components/srvpf-servers/SrvpfServerCreate"));
const SrvpfServerEdit = React.lazy(() => import("../components/srvpf-servers/SrvpfServerEdit"));
const WafRuleList = React.lazy(() => import("../components/waf-rules/WafRuleList"));
const WafRuleShow = React.lazy(() => import("../components/waf-rules/WafRuleShow"));
const WafRuleHistoryTabs = React.lazy(() => import("../components/waf-rule-histories/WafRuleHistoryTabs"));
const ProfileShow = React.lazy(() => import("../components/profile/ProfileShow"));
const ProfileEdit = React.lazy(() => import("../components/profile/ProfileEdit"));
const PasswordEdit = React.lazy(() => import("../components/profile/PasswordEdit"));
const PasswordUpdated = React.lazy(() => import("../components/profile/PasswordUpdated"));
const InformationList = React.lazy(() => import("../components/information/InformationList"));
const InformationCreate = React.lazy(() => import("../components/information/InformationCreate"));
const InformationEdit = React.lazy(() => import("../components/information/InformationEdit"));
const InquiryCreate = React.lazy(() => import("../components/inquiry/InquiryCreate"));
const MonitoringAgentList = React.lazy(() => import("../components/monitoring-agents/MonitoringAgentList"));
const MonitoringAgentCreate = React.lazy(() => import("../components/monitoring-agents/MonitoringAgentCreate"));
const MonitoringAgentEdit = React.lazy(() => import("../components/monitoring-agents/MonitoringAgentEdit"));
const MonitoringAgentDelete = React.lazy(() => import("../components/monitoring-agents/MonitoringAgentDelete"));
const NotificationList = React.lazy(() => import("../components/notifications/NotificationList"));
const BillingInfoShow = React.lazy(() => import("../components/billing-info/BillingInfoShow"));
const LicensingInfoTabs = React.lazy(() => import("../components/licensing-info/LicensingInfoTabs"));
const MonthlyReportCreate = React.lazy(() => import("../components/./monthly-reports/MonthlyReportCreate"));
const AuditLogList = React.lazy(() => import("../components/audit-logs/AuditLogList"));

const routes = [
  { path: '/', exact: true, name: 'ホーム' },
  { path: '/profile/two_factor_auth/new', exact: true, name: '二段階認証設定', component: TwoFactorAuthNew },
  { path: '/profile/two_factor_auth/delete', exact: true, name: '二段階認証解除', component: TwoFactorAuthDelete },
  { path: '/profile/two_factor_auth/code', exact: true, name: '二段階認証設定完了', component: TwoFactorAuthCode },

  { path: '/console/:customerCode/dashboard', exact: true, name: 'ダッシュボード', component: Dashboard },
  { path: '/console/:customerCode/analysis', exact: true, name: '分析ボード', component: Analysis },
  { path: '/console/:customerCode/attack_logs/', exact: true, name: '攻撃ログ', component: AttackLogList },
  { path: '/console/:customerCode/srv/waf_configs', exact: true, name: 'WAF設定一覧', component: SrvWafConfigList },
  { path: '/console/:customerCode/srv/waf_configs/new', exact: true, name: 'WAF設定新規作成', component: SrvWafConfigCreate },
  { path: '/console/:customerCode/srv/waf_configs/acl/:id', exact: true, name: 'WAF設定アクセスコントロール', component: AccessControlList },
  { path: '/console/:customerCode/srv/waf_configs/detail/:id', exact: true, name: 'WAF設定詳細', component: SrvWafConfigShow },
  { path: '/console/:customerCode/srv/monitoring_agents', exact: true, name: 'エージェント一覧', component: MonitoringAgentList },
  { path: '/console/:customerCode/srv/monitoring_agents/new', exact: true, name: 'エージェント登録', component: MonitoringAgentCreate },
  { path: '/console/:customerCode/srv/monitoring_agents/edit/:id', exact: true, name: 'エージェント編集', component: MonitoringAgentEdit },
  { path: '/console/:customerCode/srv/monitoring_agents/delete/:id', exact: true, name: 'エージェント削除', component: MonitoringAgentDelete },
  { path: '/console/:customerCode/monthly_reports/download/:monitoringAgentId', exact: true, name: '月次レポートダウンロード', component: MonthlyReportCreate },
  { path: '/console/:customerCode/audit_logs', exact: true, name: '設定変更履歴', component: AuditLogList },

  { path: '/users', exact: true, name: 'アカウント管理', component: UserList },
  { path: '/users/new', exact: true, name: 'アカウント新規作成', component: UserCreate },
  { path: '/users/edit/:id', exact: true, name: 'アカウント編集', component: UserEdit },
  { path: '/user_groups', exact: true, name: 'グループ管理', component: UserGroupList },
  { path: '/user_groups/new', exact: true, name: 'グループ新規作成', component: UserGroupCreate },
  { path: '/user_groups/show/:id', exact: true, name: 'グループ詳細', component: UserGroupShow },
  { path: '/billing_destinations', exact: true, name: '請求先管理', component: BillingDestinationList },
  { path: '/billing_destinations/new', exact: true, name: '請求先新規作成', component: BillingDestinationCreate },
  { path: '/billing_destinations/edit/:id', exact: true, name: '請求先編集', component: BillingDestinationEdit },
  { path: '/customers', exact: true, name: '顧客管理', component: CustomerList },
  { path: '/customers/new', exact: true, name: '顧客新規作成', component: CustomerCreate },
  { path: '/customers/edit/:id', exact: true, name: '顧客編集', component: CustomerEdit },
  { path: '/customers/show/:id', exact: true, name: '顧客詳細設定', component: CustomerShow },
  { path: '/distributors', exact: true, name: '代理店管理', component: DistributorList },
  { path: '/distributors/new', exact: true, name: '代理店新規作成', component: DistributorCreate },
  { path: '/distributors/edit/:id', exact: true, name: '代理店編集', component: DistributorEdit },
  // { path: '/price_plans', exact: true, name: '料金プランマスタ', component: PricePlanList },
  // { path: '/price_plans/new', exact: true, name: '料金プラン新規作成', component: PricePlanCreate },
  // { path: '/price_plans/edit/:id', exact: true, name: '料金プラン編集', component: PricePlanEdit },
  // { path: '/price_plans/delete/:id', exact: true, name: '料金プラン削除', component: PricePlanDelete },
  { path: '/licenses', exact: true, name: 'ライセンス管理', component: LicenseList },
  { path: '/licenses/new', exact: true, name: 'ライセンス新規作成', component: LicenseCreate },
  { path: '/licenses/edit/:id', exact: true, name: 'ライセンス編集', component: LicenseEdit },
  { path: '/srv_platforms', exact: true, name: '監視プラットフォームマスタ', component: SrvPlatformList },
  { path: '/srv_platforms/new', exact: true, name: '監視プラットフォーム新規作成', component: SrvPlatformCreate },
  { path: '/srv_platforms/edit/:id', exact: true, name: '監視プラットフォーム編集', component: SrvPlatformEdit },
  { path: '/srv_platforms/:srv_platform_id/srvpf_servers', exact: true, name: '監視プラットフォームサーバ一覧', component: SrvpfServerList },
  { path: '/srv_platforms/:srv_platform_id/srvpf_servers/new', exact: true, name: '監視プラットフォームサーバ新規作成', component: SrvpfServerCreate },
  { path: '/srv_platforms/:srv_platform_id/srvpf_servers/edit/:id', exact: true, name: '監視プラットフォームサーバ編集', component: SrvpfServerEdit },
  { path: '/waf_rules', exact: true, name: 'WAFルールマスタ', component: WafRuleList },
  { path: '/waf_rules/show/:id', exact: true, name: 'WAFルール詳細', component: WafRuleShow },
  { path: '/waf_rule_histories', exact: true, name: 'WAFルールインポート・配信履歴', component: WafRuleHistoryTabs },
  { path: '/profile', exact: true, name: 'プロフィール', component: ProfileShow },
  { path: '/profile/edit', exact: true, name: 'プロフィール編集', component: ProfileEdit },
  { path: '/users/password/edit', exact: true, name: "パスワード変更", component: PasswordEdit },
  { path: '/users/password/edit/done', exact: true, name: "パスワード変更完了", component: PasswordUpdated },
  { path: '/information', exact: true, name: 'お知らせ管理', component: InformationList },
  { path: '/information/new', exact: true, name: 'お知らせ新規作成', component: InformationCreate },
  { path: '/information/edit/:id', exact: true, name: 'お知らせ編集', component: InformationEdit },
  { path: '/inquiry', exact: true, name: 'お問い合わせ', component: InquiryCreate },
  { path: '/notifications', exact: true, name: 'お知らせ', component: NotificationList },
  { path: '/billing_info', exact: true, name: '請求先情報', component: BillingInfoShow },
  { path: '/licensing_info', exact: true, name: 'ライセンス情報', component: LicensingInfoTabs },
];

export default routes;

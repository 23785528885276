import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuthHeaders } from "../lib/apis/shadankun";
import { setUnauthorizedError } from "./unauthorizedErrorSlice";
import { setServerError } from "./serverErrorSlice";
import { showErrorToast } from "./errorToastSlice";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_ENDPOINT + 'api/',
  prepareHeaders: (headers, { getState }) => {
    const authHeaders = getAuthHeaders();
    Object.keys(authHeaders).forEach((key) => {
      headers.set(key, authHeaders[key]);
    });
    return headers;
  }
});

const getBaseErrors = (error) => {
  let baseErrors = []
  if (!error.data) return baseErrors;

  if (error.data.base) {
    // Devise以外のerror
    baseErrors = error.data.base;
  } else if (error.data.errors?.base) {
    // Deviseから返ってくるerror
    baseErrors = error.data.errors.base;
  }
  return baseErrors
}

const baseQueryWithErrorHandling = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (!result.error) return result;

  const err = result.error
  const baseErrors = getBaseErrors(err)

  // base errorの１番目に登録されているerror = 最初に発生したerror
  const errorMessage = baseErrors.length > 0 ? `${baseErrors[0].message}\n` : null;

  switch (err.status) {
    case 401:
      api.dispatch(setUnauthorizedError(true));
      break;
    case 402:
      api.dispatch(showErrorToast({
        'header': '登録可能な最大数を超過しました',
        'body': `${errorMessage}上限引き上げをご希望の場合は、お問い合わせください`
      }));
      break;
    case 403:
      api.dispatch(showErrorToast({
        'header': '許可されていない操作です',
        'body': `${errorMessage}許可が必要な場合は、お問い合わせください`
      }));
      break;
    case 404:
      // Just not found specific data. Do nothing.
      break;
    case 409:
      api.dispatch(showErrorToast({
        'header': '未読み込みの更新データが存在します',
        'body': '画面をリロードしてから、再度お試しください'
      }));
      break;
    case 422:
      // Deviseのvalidation errorは422で返ってくる
      if (baseErrors.length > 0 && baseErrors[0].status_code === 'payment_required') {
        api.dispatch(showErrorToast({
          'header': '登録可能な最大数を超過しました',
          'body': `${errorMessage}上限引き上げをご希望の場合は、お問い合わせください`
        }));
      } else {
        api.dispatch(setServerError(err));
      }
      break;
    default:
      api.dispatch(setServerError(err));
  }
  return result;
};

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: [],
  endpoints: builder => ({})
});
